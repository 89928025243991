.our-products-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.our-products-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.our-products-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.our-products-logo {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.our-products-image {
  width: 335px;
  height: 100px;
  object-fit: cover;
}
.our-products-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.our-products-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.our-products-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.our-products-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.our-products-link03 {
  text-decoration: none;
}
.our-products-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.our-products-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.our-products-icon {
  width: 36px;
  height: 36px;
}
.our-products-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.our-products-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.our-products-image1 {
  width: 50px;
  object-fit: cover;
}
.our-products-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.our-products-icon02 {
  width: 24px;
  height: 24px;
}
.our-products-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.our-products-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.our-products-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.our-products-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.our-products-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.our-products-link07 {
  text-decoration: none;
}
.our-products-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.our-products-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.our-products-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.our-products-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.our-products-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.our-products-icon08 {
  width: 24px;
  height: 24px;
}
.our-products-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.our-products-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.our-products-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.our-products-text03 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.our-products-cta-btn1 {
  color: var(--dl-color-gray-white);
  align-self: stretch;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: #e8611e;
}
.our-products-image2 {
  width: 50%;
  object-fit: contain;
}
.our-products-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.our-products-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.our-products-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.our-products-text04 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-text05 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.our-products-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.our-products-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.our-products-image3 {
  width: 356px;
  object-fit: cover;
  margin-bottom: 20px;
}
.our-products-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.our-products-text06 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.our-products-feature-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.our-products-image4 {
  width: 235px;
  object-fit: cover;
  margin-bottom: 20px;
}
.our-products-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.our-products-text07 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.our-products-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.our-products-image5 {
  width: 299px;
  object-fit: cover;
  margin-bottom: 20px;
}
.our-products-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.our-products-text08 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.our-products-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.our-products-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.our-products-gallery {
  width: 100%;
  display: grid;
  padding: 48px;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  grid-template-columns: 1fr 1fr 1fr;
}
.our-products-gallery-card {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.our-products-image6 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.our-products-service-card {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.our-products-card-content {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.our-products-text09 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-text10 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-button {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.our-products-service-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.our-products-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.our-products-text11 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-text12 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-button1 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.our-products-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.our-products-service-card2 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.our-products-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.our-products-text13 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-text14 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-products-button2 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.our-products-gallery1 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
}
.our-products-text15 {
  font-size: 3rem;
  text-align: center;
}
.our-products-text16 {
  color: var(--dl-color-gray-700);
  margin-top: 32px;
  text-align: center;
  padding-left: 48px;
  margin-bottom: 32px;
  padding-right: 48px;
}
.our-products-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.our-products-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.our-products-stats {
  width: 1121px;
  height: 329px;
  display: grid;
  padding: var(--dl-space-space-tenunits);
  grid-gap: 32px;
  max-width: 1400px;
  padding-top: 69px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.our-products-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.our-products-text23 {
  color: #FF7C4C;
  font-size: 3rem;
}
.our-products-text26 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-text27 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-stat1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.our-products-text28 {
  color: #FF7C4C;
  font-size: 3rem;
}
.our-products-text30 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-text31 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-stat2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.our-products-text32 {
  color: #FF7C4C;
  font-size: 3rem;
}
.our-products-text35 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-text36 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-stat3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.our-products-text37 {
  color: #FF7C4C;
  font-size: 3rem;
}
.our-products-text39 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-text40 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.our-products-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.our-products-image7 {
  width: 752px;
  align-self: center;
  object-fit: cover;
}
.our-products-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.our-products-container5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.our-products-logo1 {
  display: flex;
  max-width: 1400px;
  align-items: flex-start;
  flex-direction: column;
}
.our-products-image8 {
  width: 242px;
  height: 199px;
}
.our-products-text41 {
  font-size: 33px;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  padding-top: 0px;
}
.our-products-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.our-products-container6 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.our-products-product-container {
  width: 85px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-right: 73px;
  flex-direction: column;
  justify-content: flex-start;
}
.our-products-text48 {
  font-weight: 700;
  margin-bottom: 24px;
}
.our-products-text49 {
  margin-bottom: var(--dl-space-space-unit);
}
.our-products-text50 {
  margin-bottom: var(--dl-space-space-unit);
}
.our-products-text51 {
  margin-bottom: var(--dl-space-space-unit);
}
.our-products-company-container {
  width: 99px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.our-products-text53 {
  font-weight: 700;
  margin-bottom: 24px;
}
.our-products-text54 {
  margin-bottom: var(--dl-space-space-unit);
}
.our-products-text55 {
  margin-bottom: var(--dl-space-space-unit);
}
.our-products-text56 {
  margin-bottom: var(--dl-space-space-unit);
}
.our-products-location-1 {
  width: 324px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.our-products-heading4 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.our-products-adress {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.our-products-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.our-products-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.our-products-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.our-products-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.our-products-icon14 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.our-products-text71 {
  font-weight: 700;
  margin-bottom: 24px;
}
.our-products-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.our-products-icon16 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.our-products-icon18 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.our-products-icon20 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.our-products-icon22 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.our-products-icon24 {
  width: 24px;
  height: 24px;
}
.our-products-container8 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.our-products-socials {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.our-products-separator {
  width: 100%;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: var(--dl-color-gray-900);
}
.our-products-text72 {
  align-self: center;
}
@media(max-width: 991px) {
  .our-products-image {
    width: 271px;
    height: 94px;
  }
  .our-products-hero {
    flex-direction: column-reverse;
  }
  .our-products-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .our-products-text03 {
    color: var(--dl-color-gray-500);
  }
  .our-products-cta-btn1 {
    width: 40%;
  }
  .our-products-image2 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .our-products-feature-card {
    align-self: center;
  }
  .our-products-gallery {
    position: relative;
    grid-template-columns: 1fr 1fr;
  }
  .our-products-text16 {
    text-align: center;
  }
  .our-products-container4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .our-products-stats {
    width: 963px;
    height: 202px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .our-products-text23 {
    text-align: center;
  }
  .our-products-text28 {
    text-align: center;
  }
  .our-products-text32 {
    text-align: center;
  }
  .our-products-text37 {
    text-align: center;
  }
  .our-products-contact {
    height: 878px;
  }
  .our-products-image7 {
    width: 633px;
    align-self: center;
  }
  .our-products-footer {
    flex-direction: column;
  }
  .our-products-logo1 {
    width: 349px;
    height: 419px;
  }
  .our-products-image8 {
    width: 294px;
    height: 230px;
  }
  .our-products-text41 {
    align-self: center;
  }
  .our-products-container6 {
    margin-right: var(--dl-space-space-fiveunits);
  }
  .our-products-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .our-products-location-1 {
    width: 307px;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: 0px;
  }
  .our-products-icon16 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .our-products-icon18 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .our-products-icon20 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .our-products-icon22 {
    margin-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .our-products-image {
    width: 535px;
    height: 152px;
    padding-right: 0px;
  }
  .our-products-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .our-products-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-black);
  }
  .our-products-icon {
    fill: var(--dl-color-gray-white);
  }
  .our-products-heading {
    text-align: center;
  }
  .our-products-text03 {
    text-align: center;
  }
  .our-products-cta-btn1 {
    width: auto;
    align-self: center;
  }
  .our-products-heading-container {
    width: 100%;
  }
  .our-products-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .our-products-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .our-products-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .our-products-feature-card2 {
    width: 70%;
  }
  .our-products-gallery {
    padding-left: 32px;
    padding-right: 32px;
  }
  .our-products-card-content {
    margin: 0px;
  }
  .our-products-card-content1 {
    margin: 0px;
  }
  .our-products-card-content2 {
    margin: 0px;
  }
  .our-products-gallery1 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .our-products-text16 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .our-products-container4 {
    grid-template-columns: 1fr 1fr;
  }
  .our-products-stats {
    width: 100%;
    height: 455px;
    padding-left: 119px;
    padding-right: 132px;
    grid-template-columns: 1fr 1fr;
  }
  .our-products-text26 {
    text-align: center;
  }
  .our-products-text30 {
    text-align: center;
  }
  .our-products-text35 {
    text-align: center;
  }
  .our-products-text39 {
    text-align: center;
  }
  .our-products-contact {
    width: 100%;
    height: 687px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .our-products-image7 {
    width: 100%;
    height: 637px;
  }
  .our-products-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .our-products-container5 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .our-products-logo1 {
    height: 287px;
    position: relative;
    align-items: center;
  }
  .our-products-image8 {
    width: 148px;
    height: 146px;
    margin-bottom: 0px;
  }
  .our-products-text41 {
    font-size: 33px;
    align-self: center;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    text-align: center;
    font-weight: 700;
    line-height: 1;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .our-products-container6 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .our-products-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .our-products-location-1 {
    width: 339px;
    align-self: center;
    margin-top: 0px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
  }
  .our-products-container7 {
    height: 28px;
  }
  .our-products-icon16 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .our-products-icon18 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-tripleunit);
  }
  .our-products-icon20 {
    align-self: flex-end;
  }
  .our-products-icon22 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-tripleunit);
  }
  .our-products-icon24 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 479px) {
  .our-products-image {
    width: 354px;
    height: 95px;
    align-self: center;
  }
  .our-products-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .our-products-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .our-products-hero-text-container {
    width: 100%;
  }
  .our-products-image2 {
    width: 100%;
  }
  .our-products-feature-card {
    width: 100%;
  }
  .our-products-feature-card1 {
    width: 100%;
  }
  .our-products-feature-card2 {
    width: 100%;
  }
  .our-products-gallery {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .our-products-service-card {
    width: 100%;
  }
  .our-products-service-card1 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .our-products-service-card2 {
    width: 100%;
  }
  .our-products-gallery1 {
    height: 3973px;
    padding: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .our-products-container4 {
    grid-template-columns: 1fr;
  }
  .our-products-stats {
    position: relative;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
    grid-template-columns: 1fr;
  }
  .our-products-stat {
    width: auto;
  }
  .our-products-stat1 {
    width: auto;
    margin-right: 0px;
  }
  .our-products-stat2 {
    width: auto;
    height: 158px;
  }
  .our-products-contact {
    height: 789px;
    padding-top: 289px;
  }
  .our-products-image7 {
    height: 440px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .our-products-footer {
    padding: var(--dl-space-space-unit);
  }
  .our-products-container5 {
    align-items: center;
    flex-direction: column;
  }
  .our-products-image8 {
    width: 123px;
    height: 115px;
    margin-bottom: 0px;
  }
  .our-products-text41 {
    margin-top: var(--dl-space-space-halfunit);
    line-height: 1.3;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .our-products-links-container {
    height: 606px;
    align-items: center;
    flex-direction: column;
  }
  .our-products-container6 {
    height: 240px;
    margin-right: 0px;
  }
  .our-products-location-1 {
    height: 285px;
    margin-top: var(--dl-space-space-unit);
    margin-right: 0px;
  }
  .our-products-heading4 {
    font-size: 24px;
  }
  .our-products-link08 {
    text-decoration: underline;
  }
  .our-products-text69 {
    text-decoration: underline;
  }
  .our-products-link10 {
    text-decoration: underline;
  }
  .our-products-text71 {
    font-weight: 700;
  }
  .our-products-icon20 {
    align-self: flex-end;
  }
  .our-products-icon22 {
    align-self: flex-end;
  }
  .our-products-container8 {
    margin-top: 24px;
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .our-products-text72 {
    text-align: center;
  }
}
