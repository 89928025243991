.contact-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-us-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.contact-us-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.contact-us-logo {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-us-image {
  width: 335px;
  height: 100px;
  object-fit: cover;
}
.contact-us-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-us-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.contact-us-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.contact-us-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.contact-us-link03 {
  text-decoration: none;
}
.contact-us-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.contact-us-icon {
  width: 36px;
  height: 36px;
}
.contact-us-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.contact-us-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-image1 {
  width: 50px;
  object-fit: cover;
}
.contact-us-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-icon02 {
  width: 24px;
  height: 24px;
}
.contact-us-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.contact-us-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-link07 {
  text-decoration: none;
}
.contact-us-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.contact-us-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.contact-us-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.contact-us-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.contact-us-icon08 {
  width: 24px;
  height: 24px;
}
.contact-us-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.contact-us-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.contact-us-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.contact-us-text03 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.contact-us-cta-btn1 {
  color: var(--dl-color-gray-white);
  align-self: stretch;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.contact-us-image2 {
  width: 50%;
  object-fit: contain;
}
.contact-us-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.contact-us-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.contact-us-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.contact-us-text04 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-text05 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.contact-us-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.contact-us-image3 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.contact-us-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.contact-us-text06 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.contact-us-feature-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.contact-us-image4 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.contact-us-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.contact-us-text07 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.contact-us-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.contact-us-image5 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.contact-us-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.contact-us-text08 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.contact-us-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.contact-us-gallery {
  width: 100%;
  display: grid;
  padding: 48px;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  grid-template-columns: 1fr 1fr 1fr;
}
.contact-us-gallery-card {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-us-image6 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.contact-us-service-card {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.contact-us-card-content {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-us-text09 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-text10 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-button {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.contact-us-service-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.contact-us-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-us-text11 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-text12 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-button1 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.contact-us-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.contact-us-service-card2 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.contact-us-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-us-text13 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-text14 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.contact-us-button2 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.contact-us-gallery1 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
}
.contact-us-text15 {
  font-size: 3rem;
  text-align: center;
}
.contact-us-text16 {
  color: var(--dl-color-gray-700);
  margin-top: 32px;
  text-align: center;
  padding-left: 48px;
  margin-bottom: 32px;
  padding-right: 48px;
}
.contact-us-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.contact-us-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.contact-us-stats {
  width: 1121px;
  height: 329px;
  display: grid;
  padding: var(--dl-space-space-tenunits);
  grid-gap: 32px;
  max-width: 1400px;
  padding-top: 69px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.contact-us-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-text23 {
  color: #FF7C4C;
  font-size: 3rem;
}
.contact-us-text26 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-text27 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-stat1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-text28 {
  color: #FF7C4C;
  font-size: 3rem;
}
.contact-us-text30 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-text31 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-stat2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-text32 {
  color: #FF7C4C;
  font-size: 3rem;
}
.contact-us-text35 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-text36 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-stat3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-text37 {
  color: #FF7C4C;
  font-size: 3rem;
}
.contact-us-text39 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-text40 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.contact-us-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.contact-us-image7 {
  width: 752px;
  align-self: center;
  object-fit: cover;
}
.contact-us-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.contact-us-container5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-logo1 {
  display: flex;
  max-width: 1400px;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-image8 {
  width: 242px;
  height: 199px;
}
.contact-us-text41 {
  font-size: 33px;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  padding-top: 0px;
}
.contact-us-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-container6 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-product-container {
  width: 85px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-right: 73px;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-text48 {
  font-weight: 700;
  margin-bottom: 24px;
}
.contact-us-text49 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-text50 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-text51 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-company-container {
  width: 99px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-text53 {
  font-weight: 700;
  margin-bottom: 24px;
}
.contact-us-text54 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-text55 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-text56 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-location-1 {
  width: 324px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-heading4 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.contact-us-adress {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.contact-us-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.contact-us-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.contact-us-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.contact-us-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.contact-us-icon14 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.contact-us-text71 {
  font-weight: 700;
  margin-bottom: 24px;
}
.contact-us-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.contact-us-icon16 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.contact-us-icon18 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.contact-us-icon20 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.contact-us-icon22 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-tripleunit);
}
.contact-us-icon24 {
  width: 24px;
  height: 24px;
}
.contact-us-container8 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-socials {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-separator {
  width: 100%;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: var(--dl-color-gray-900);
}
.contact-us-text72 {
  align-self: center;
}
@media(max-width: 991px) {
  .contact-us-image {
    width: 271px;
    height: 94px;
  }
  .contact-us-hero {
    flex-direction: column-reverse;
  }
  .contact-us-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .contact-us-text03 {
    color: var(--dl-color-gray-500);
  }
  .contact-us-cta-btn1 {
    width: 40%;
  }
  .contact-us-image2 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .contact-us-feature-card {
    align-self: center;
  }
  .contact-us-gallery {
    position: relative;
    grid-template-columns: 1fr 1fr;
  }
  .contact-us-text16 {
    text-align: center;
  }
  .contact-us-container4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .contact-us-stats {
    height: 202px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contact-us-text23 {
    text-align: center;
  }
  .contact-us-text28 {
    text-align: center;
  }
  .contact-us-text32 {
    text-align: center;
  }
  .contact-us-text37 {
    text-align: center;
  }
  .contact-us-contact {
    height: 878px;
  }
  .contact-us-image7 {
    width: 633px;
    align-self: center;
  }
  .contact-us-footer {
    flex-direction: column;
  }
  .contact-us-logo1 {
    width: 349px;
    height: 419px;
  }
  .contact-us-image8 {
    width: 294px;
    height: 230px;
  }
  .contact-us-text41 {
    align-self: center;
  }
  .contact-us-container6 {
    margin-right: var(--dl-space-space-fiveunits);
  }
  .contact-us-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-us-location-1 {
    width: 307px;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: 0px;
  }
  .contact-us-icon16 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .contact-us-icon18 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .contact-us-icon20 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .contact-us-icon22 {
    margin-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .contact-us-image {
    width: 535px;
    height: 152px;
    padding-right: 0px;
  }
  .contact-us-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .contact-us-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-black);
  }
  .contact-us-icon {
    fill: var(--dl-color-gray-white);
  }
  .contact-us-heading {
    text-align: center;
  }
  .contact-us-text03 {
    text-align: center;
  }
  .contact-us-cta-btn1 {
    width: auto;
    align-self: center;
  }
  .contact-us-heading-container {
    width: 100%;
  }
  .contact-us-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .contact-us-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .contact-us-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .contact-us-feature-card2 {
    width: 70%;
  }
  .contact-us-gallery {
    padding-left: 32px;
    padding-right: 32px;
  }
  .contact-us-card-content {
    margin: 0px;
  }
  .contact-us-card-content1 {
    margin: 0px;
  }
  .contact-us-card-content2 {
    margin: 0px;
  }
  .contact-us-gallery1 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .contact-us-text16 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-us-container4 {
    grid-template-columns: 1fr 1fr;
  }
  .contact-us-stats {
    height: 455px;
    padding-left: 32px;
    padding-right: 32px;
    grid-template-columns: 1fr 1fr;
  }
  .contact-us-text26 {
    text-align: center;
  }
  .contact-us-text30 {
    text-align: center;
  }
  .contact-us-text35 {
    text-align: center;
  }
  .contact-us-text39 {
    text-align: center;
  }
  .contact-us-contact {
    width: 100%;
    height: 687px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .contact-us-image7 {
    width: 100%;
    height: 637px;
  }
  .contact-us-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .contact-us-container5 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-us-logo1 {
    height: 287px;
    position: relative;
    align-items: center;
  }
  .contact-us-image8 {
    width: 148px;
    height: 146px;
    margin-bottom: 0px;
  }
  .contact-us-text41 {
    font-size: 33px;
    align-self: center;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    text-align: center;
    font-weight: 700;
    line-height: 1;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-us-container6 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-us-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-us-location-1 {
    width: 339px;
    align-self: center;
    margin-top: 0px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
  }
  .contact-us-container7 {
    height: 28px;
  }
  .contact-us-icon16 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .contact-us-icon18 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-tripleunit);
  }
  .contact-us-icon20 {
    align-self: flex-end;
  }
  .contact-us-icon22 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-tripleunit);
  }
  .contact-us-icon24 {
    align-self: flex-end;
    margin-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 479px) {
  .contact-us-image {
    width: 326px;
    height: 95px;
    align-self: center;
  }
  .contact-us-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .contact-us-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .contact-us-hero-text-container {
    width: 100%;
  }
  .contact-us-image2 {
    width: 100%;
  }
  .contact-us-feature-card {
    width: 100%;
  }
  .contact-us-feature-card1 {
    width: 100%;
  }
  .contact-us-feature-card2 {
    width: 100%;
  }
  .contact-us-gallery {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .contact-us-service-card {
    width: 100%;
  }
  .contact-us-service-card1 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .contact-us-service-card2 {
    width: 100%;
  }
  .contact-us-gallery1 {
    height: 3973px;
    padding: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .contact-us-container4 {
    grid-template-columns: 1fr;
  }
  .contact-us-stats {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
    grid-template-columns: 1fr;
  }
  .contact-us-contact {
    height: 789px;
    padding-top: 289px;
  }
  .contact-us-image7 {
    height: 440px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .contact-us-footer {
    padding: var(--dl-space-space-unit);
  }
  .contact-us-container5 {
    align-items: center;
    flex-direction: column;
  }
  .contact-us-image8 {
    width: 123px;
    height: 115px;
    margin-bottom: 0px;
  }
  .contact-us-text41 {
    margin-top: var(--dl-space-space-halfunit);
    line-height: 1.3;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-us-links-container {
    height: 606px;
    align-items: center;
    flex-direction: column;
  }
  .contact-us-container6 {
    height: 240px;
    margin-right: 0px;
  }
  .contact-us-location-1 {
    height: 285px;
    margin-top: var(--dl-space-space-unit);
    margin-right: 0px;
  }
  .contact-us-heading4 {
    font-size: 24px;
  }
  .contact-us-link08 {
    text-decoration: underline;
  }
  .contact-us-text69 {
    text-decoration: underline;
  }
  .contact-us-link10 {
    text-decoration: underline;
  }
  .contact-us-text71 {
    font-weight: 700;
  }
  .contact-us-icon20 {
    align-self: flex-end;
  }
  .contact-us-icon22 {
    align-self: flex-end;
  }
  .contact-us-container8 {
    margin-top: 24px;
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .contact-us-text72 {
    text-align: center;
  }
}
