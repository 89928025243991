.gallery-card1-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card1-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-card1-text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery-card1-text1 {
  color: var(--dl-color-gray-500);
  align-self: flex-start;
}


















@media(max-width: 991px) {
  .gallery-card1-root-class-name {
    height: 70%;
  }
  .gallery-card1-root-class-name1 {
    height: 70%;
  }
  .gallery-card1-root-class-name2 {
    height: 100%;
  }
  .gallery-card1-root-class-name5 {
    width: 100%;
    height: auto;
  }
  .gallery-card1-root-class-name6 {
    height: 70%;
  }
  .gallery-card1-root-class-name7 {
    height: 70%;
  }
  .gallery-card1-root-class-name8 {
    height: 100%;
  }
  .gallery-card1-root-class-name11 {
    width: 100%;
    height: auto;
  }
  .gallery-card1-root-class-name12 {
    height: 70%;
  }
  .gallery-card1-root-class-name13 {
    height: 70%;
  }
  .gallery-card1-root-class-name14 {
    height: 100%;
  }
  .gallery-card1-root-class-name17 {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 767px) {
  .gallery-card1-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card1-image {
    height: 10rem;
  }
}
